import { debounce } from 'lodash-es'
import { loadOverlay } from '../util/overlays'
import { initKioskBackButton } from './components/back-button'

export default class Kiosk {
    /* @var Number  */
    timeout = 0

    /* @var Number */
    timer = null

    /**
     * @param {HTMLElement} elm
     * @param {Object} options
     */
    constructor() {
        if (!this.getKioskCookie()) {
            return;
        }

        initKioskBackButton();

        const timeout = this.getTimeoutValue()
        if (!timeout) {
            return;
        }

        this.timeout = timeout
        this.addEventListeners()
    }

    /**
     * Get cookie by name
     */
    getKioskCookie() {
        const value = `; ${document.cookie}`
        const parts = value.split(`; kiosk=`)
        if (parts.length === 2) {
            const data = decodeURIComponent(parts.pop().split(';').shift())
            try {
                return JSON.parse(data)
            } catch { }
        }

        return null
    }

    /**
     * Get timeout value from cookie
     */
    getTimeoutValue() {
        const KioskCookie = this.getKioskCookie()

        if (KioskCookie && typeof KioskCookie[0] !== 'undefined' && !isNaN(KioskCookie[0])) {
            return parseInt(KioskCookie[0]) * 1000
        }

        return null
    }

    /**
     * Show the overlay
     */
    showOverlay() {
        loadOverlay('/kiosk/overlay', {
            modifiers: ['takeover', 'inactivity'],
        })

        const cookieBanner = document.querySelector('#onetrust-consent-sdk');

        if (cookieBanner) {
            cookieBanner.remove();
        }
    }

    /**
     * Debounced timer method
     */
    timerReset = debounce(() => {
        clearTimeout(this.timer)
        this.timer = setTimeout(this.showOverlay, this.timeout)
    }, 1000)

    /**
     * Add event listeners to body/overlay
     */
    addEventListeners() {
        const events = ['click', 'touchstart', 'touchmove', 'wheel', 'pointerdown', 'pointermove', 'scroll', 'keydown', 'mousemove'];
        events.forEach(event => document.body.addEventListener(event, () => {
            this.timerReset()
        }))

        this.timerReset()
    }
}
